<template>
  <div class="animated fadeIn">
    <TableWrapper
      :ref="model.name"
      :parent-id="invoiceId"
      :loading="isLoading"
      actions-mode="event"
      :model="model"
      :columns="columns"
      :filter-by-column="true"
      @create="showBottlingsModal"
      @updated="onUpdate"
      @deleted="onDelete"
      @loaded="onLoad"
    ></TableWrapper>

    <WarehouseLookupModal ref="warehouse-lookup" @select="onBottlingsAdd"></WarehouseLookupModal>
  </div>
</template>

<script>
import {FORM_MODE} from '@/shared/constants'
import TableWrapper from '@/components/DataView/TableWrapper'
import models from '@/models'
import productServices from '@/services/SalesAdministrative/products.service'

import WarehouseLookupModal from '@/views/Warehouse/PickLists/WarehouseLookupModal.vue'

export default {
  name: 'InvoiceItemsTable',
  props: {
    invoiceId: {
      type: [String, Number],
      default: ''
    },
    mode: {
      type: [Number],
      default: FORM_MODE.VIEW
    },
    formData: {
      type: Object,
      default: () => {}
    }
  },
  components: {TableWrapper, WarehouseLookupModal},
  data: function () {
    return {
      isLoading: false,
      model: models.warehouse.invoiceItems,
      columns: models.warehouse.invoiceItems.entities.map(e => e.name),
      cellClasses: {
        'Bottles Diff': [
          {
            class: 'bg-danger',
            condition: row => +row['Bottles Diff'] < 0
          },
          {
            class: 'bg-success',
            condition: row => +row['Bottles Diff'] > 0
          }
        ],
        'Cases Diff': [
          {
            class: 'bg-danger',
            condition: row => +row['Cases Diff'] < 0
          },
          {
            class: 'bg-success',
            condition: row => +row['Cases Diff'] > 0
          }
        ]
      },
      customActions: [
        /*
        {
          click: this.onItemsLookupClick,
          title: "Warehouse",
          icon: "search"
        }
        */
      ],
      dictionaries: {
        finishedProducts: [],
        products: []
      }
    }
  },
  created () {
    this.initialize()
  },
  async mounted () {
    this.$api.get(`/dictionaries/finished-products`).then(response => {
      this.dictionaries.finishedProducts = response
    })

    const response = await productServices.fetchProducts()

    this.dictionaries.products = response
  },
  methods: {
    async initialize () {},
    getData () {
      return this.$refs[this.model.name].getData()
    },
    getDataSet () {
      return this.$refs[this.model.name].getDataSet()
    },
    updateComputedColumns () {

      console.log('updateComputedColumns.row', row)
      let data = this.$refs[this.model.name].getDataSet()

      //console.log('this.dictionaries.products', this.dictionaries.products)

      for (let row of data) {
        let productId = row['Product_ID']

        let product = this.dictionaries.products.find(p => p.ID == productId)
        console.log('updateComputedColumns.product:', product)
        const bottlesPerCase = product ? product['Case Size'] : -2000; //18-02-25: added -2000 to prevent error when product is not found
console.log('bottlesPerCase:',  product['Case Size'] )
        row['Line Cost'] = this.$helpers.round((row['Cost per Case'] * row['Bottles']) / bottlesPerCase, 4)

        row['Cases'] = this.$helpers.round(row['Bottles'] / bottlesPerCase, 2)
      }

      this.$refs[this.model.name].updateDataSet(data)
    },
    onItemsLookupClick () {},
    showBottlingsModal () {
      let conditions = {
        'Warehouse ID': this.formData['Warehouse'].id,
        Warehouse: this.formData['Warehouse'].label
      }

      this.$refs['warehouse-lookup'].show(conditions)
    },
    async onBottlingsAdd (e) {
      console.log('onBottlingsAdd:', e)

      let data = this.getDataSet()

      for (let i = 0; i < e.length; i++) {
        let r = e[i]

        let product = this.dictionaries.products.find(p => p.ID == r['Product ID'])

        console.log('onBottlingsAdd.r:', r)
        console.log('onBottlingsAdd.product:', product)
        let row = {
          //ID: this.$helpers.uid8(),
          ID: '',
          'Invoice ID': this.invoiceId,
          'Batch Number': r['Batch Number'],
          'Bottle Size': r['Bottle Size'],
          Bottles: +r['Bottles Selected'],
          'Bottling Number': r['Bottling Number'],
          Product_ID: r['Product_ID'],
          Product: r['Product'],
          'Finished Product': r['Finished Product'],
          'Finished Product ID': r['finished_product_id'],
          Cases: 0,
          'Cases per Pallet': product ? product['Cases per Layer'] * product['Layers per Pallet'] : null,
          'Cost per Case': +r['Cost per Case'] ? +r['Cost per Case'] : 0,
          'Line Cost': 0,
          'Organic Status': r['Organic Status'],
          'Organic Status_ID': r['Organic Status ID'],
          Deleted: 0
        }
        data.push(row)
      }

      this.$refs[this.model.name].updateDataSet(data)

      this.updateComputedColumns()

      this.$emit('changed', this.getDataSet())
    },
    async onUpdate () {
      this.updateComputedColumns()
      this.$emit('changed', this.getDataSet())
    },

    async onDelete () {
      this.updateComputedColumns()
      this.$emit('changed', this.getDataSet())
    },
    onLoad (e) {
      this.$emit('loaded', e)
    }
  }
}
</script>

<style scoped></style>
