var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('DetailsFormWrapper', {
    ref: "form",
    attrs: {
      "id": _vm.id,
      "action": _vm.action,
      "model": _vm.model,
      "data": _vm.data,
      "custom-buttons": _vm.customButtons,
      "header": "Invoice"
    },
    on: {
      "custom-click": function customClick(name) {
        _this[name]();
      },
      "loaded": _vm.onFormLoad
    }
  }, [_vm._t("content", function () {
    return [_c('b-tabs', {
      attrs: {
        "content-class": "mt-3",
        "justified": ""
      }
    }, [_c('b-tab', {
      attrs: {
        "title": "Overview",
        "active": ""
      }
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('ID')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['ID'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'ID', $$v);
        },
        expression: "data['ID']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormDateTime', {
      attrs: {
        "properties": _vm.entity('Invoice Date')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Invoice Date'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Invoice Date', $$v);
        },
        expression: "data['Invoice Date']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormDateTime', {
      attrs: {
        "properties": _vm.entity('Shipment Date')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Shipment Date'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Shipment Date', $$v);
        },
        expression: "data['Shipment Date']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormSelect', {
      attrs: {
        "properties": _vm.entity('Distributor')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Distributor'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Distributor', $$v);
        },
        expression: "data['Distributor']"
      }
    })], 1)], 1), _c('b-row', [_c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('PO Number')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['PO Number'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'PO Number', $$v);
        },
        expression: "data['PO Number']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormDateTime', {
      attrs: {
        "properties": _vm.entity('PO Date')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['PO Date'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'PO Date', $$v);
        },
        expression: "data['PO Date']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormSelect', {
      attrs: {
        "properties": _vm.entity('Warehouse')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Warehouse'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Warehouse', $$v);
        },
        expression: "data['Warehouse']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormSelect', {
      attrs: {
        "properties": _vm.entity('Currency')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Currency'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Currency', $$v);
        },
        expression: "data['Currency']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "2",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Status')
      },
      model: {
        value: _vm.data['Status'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Status', $$v);
        },
        expression: "data['Status']"
      }
    })], 1)], 1), _c('b-row', [_c('b-col', {
      attrs: {
        "lg": "12",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Notes')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Notes'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Notes', $$v);
        },
        expression: "data['Notes']"
      }
    })], 1)], 1), _c('b-row', [_c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Created By')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Created By'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Created By', $$v);
        },
        expression: "data['Created By']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Created')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Created'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Created', $$v);
        },
        expression: "data['Created']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Modified By')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Modified By'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Modified By', $$v);
        },
        expression: "data['Modified By']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Modified')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Modified'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Modified', $$v);
        },
        expression: "data['Modified']"
      }
    })], 1)], 1), _c('hr'), _c('InvoiceItemsTable', {
      ref: "invoice-items",
      attrs: {
        "invoice-id": _vm.id,
        "form-data": _vm.data
      },
      on: {
        "changed": _vm.onItemsChange,
        "loaded": _vm.onItemsLoad
      }
    }), _c('b-row', [_c('b-col', {
      attrs: {
        "lg": "6",
        "md": "6",
        "sm": "6"
      }
    }, [_c('div', _vm._l(_vm.groupedProducts, function (group, productId) {
      return _c('div', {
        key: productId,
        staticClass: "product-group"
      }, [_c('b-table-simple', {
        staticClass: "table table-striped",
        attrs: {
          "small": "",
          "bordered": "",
          "responsive": ""
        }
      }, [_c('b-thead', [_c('b-tr', {
        staticClass: "bg-light"
      }, [_c('b-th', {
        staticClass: "text-left",
        attrs: {
          "colspan": "3"
        }
      }, [_vm._v(_vm._s(group.productName) + " ( " + _vm._s(group.pallets.length) + " pallets, " + _vm._s(group.pallets.reduce(function (sum, p) {
        return sum + parseFloat(p.weight);
      }, 0).toFixed(2)) + " lbs, " + _vm._s(group.pallets.reduce(function (sum, p) {
        return sum + p.fullCases;
      }, 0)) + " cases, " + _vm._s(group.item.Bottles) + " bottles total)")])], 1)], 1), _c('b-tbody', _vm._l(group.pallets, function (pallet) {
        return _c('b-tr', {
          key: pallet.palletNumber
        }, [_c('b-td', [_vm._v(_vm._s(pallet.palletNumber))]), _c('b-td', [_vm._v(_vm._s(pallet.weight) + " lbs")]), _c('b-td', [_vm._v(" [" + _vm._s(pallet.layerInfo) + ", " + _vm._s(pallet.fullCases) + " cases" + _vm._s(pallet.extraBottles ? ' + ' + pallet.extraBottles + ' bottles' : '') + ", " + _vm._s(pallet.totalBottles) + " bottles total] ")])], 1);
      }), 1)], 1)], 1);
    }), 0)]), _c('b-col', {
      attrs: {
        "lg": "6",
        "md": "6",
        "sm": "6"
      }
    }, [_c('b-table', {
      attrs: {
        "items": _vm.productsTotals,
        "responsive": ""
      }
    })], 1)], 1), _c('b-row', [_c('b-col', {
      attrs: {
        "lg": "6",
        "md": "6",
        "sm": "6"
      }
    }), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Discount')
      },
      on: {
        "change": _vm.onFieldChange
      },
      model: {
        value: _vm.data['Discount'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Discount', $$v);
        },
        expression: "data['Discount']"
      }
    })], 1), _c('b-col', {
      attrs: {
        "lg": "3",
        "md": "6",
        "sm": "6"
      }
    }, [_c('FormInput', {
      attrs: {
        "properties": _vm.entity('Total')
      },
      model: {
        value: _vm.data['Total'],
        callback: function callback($$v) {
          _vm.$set(_vm.data, 'Total', $$v);
        },
        expression: "data['Total']"
      }
    })], 1)], 1)], 1), _vm.$form.mode(this) !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
      attrs: {
        "title": _vm.controls.tabs.files.title
      }
    }, [_c('files-container', {
      attrs: {
        "module-id": _vm.$route.meta.module.id,
        "entity-id": _vm.id
      },
      on: {
        "loaded": _vm.onFileContainerLoad
      }
    })], 1) : _vm._e()], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }